<template>
        <van-form @submit="onSubmit">
          <van-cell-group title="派车单信息" icon="friends-o" inset>
            <van-field
                v-model="id"
                name="id"
                v-show="false"
            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderRfq.needStartTime']"
                name="dispatchOrderRfq.needStartTime"
                label="需要发车时间"
                placeholder="需要发车时间"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderRfq.needReachTime']"
                name="dispatchOrderRfq.needReachTime"
                label="需要到达时间"
                placeholder="需要到达时间"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderRfq.dispatchOrder.volume']"
                name="dispatchOrderRfq.dispatchOrder.volume"
                label="体积"
                placeholder="体积"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderRfq.mileage']"
                name="dispatchOrderRfq.mileage"
                label="里程（米）"
                placeholder="里程"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="nameStart"
                name="nameStart"
                label="起始点"
                placeholder="起始点"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderRfq.dispatchOrder.addressStart']"
                name="dispatchOrderRfq.dispatchOrder.addressStart"
                label="起始点位置"
                placeholder="起始点位置"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="nameEnd"
                name="nameEnd"
                label="终点"
                placeholder="终点"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderRfq.dispatchOrder.addressEnd']"
                name="volume"
                label="终点位置"
                placeholder="终点位置"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="driverName"
                name="driverName"
                label="司机姓名"
                placeholder="司机姓名"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="driverPhone"
                name="driverPhone"
                label="司机手机号"
                placeholder="司机手机号"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="this.$data['quotePrice']"
                name="quotePrice"
                label="填报报价"
                placeholder="报价"
            ></van-field>
            <Picker
                name="currency.id"
                label="币种"
                placeholder="币种"
                displayField="name"
                :select-first="true"
                v-model:value="this.$data['currency.id']"
                :defConfig="{
            portName: 'tms.basic.Currency.getList'
           }"
                :canSearch=false
            ></Picker>
            <Picker
                name="vehicle.id"
                label="车辆"
                placeholder="车辆"
                displayField="carCodeHead"
                v-model:value="this.$data['vehicle.id']"
                :defConfig="{
            portName: 'tms.TmsVehicle.getTmsVehicles',
            data:{vapp:'vapp',vid:this.$data['vehicle.id']}
           }"
                :canSearch=false
            ></Picker>
            <Picker
                name="carriage.id"
                label="车厢"
                placeholder="车厢"
                displayField="code"
                v-model:value="this.$data['carriage.id']"
                :defConfig="{
            portName: 'tms.TmsBasicCarriage.getTmsBasicCarriages'
           }"
                :canSearch=false
            ></Picker>
            <Picker
                name="coupler.id"
                label="车挂"
                placeholder="车挂"
                displayField="licensePlate"
                v-model:value="this.$data['coupler.id']"
                :defConfig="{
            portName: 'tms.TmsBasicCoupler.getTmsBasicCouplers'
           }"
                :canSearch=false
            ></Picker>

          </van-cell-group>



          <div style="margin: 4.26667vw">
            <van-button round block type="primary" native-type="submit" :disabled="btnDisabled" >
              保存信息
            </van-button>
            <van-button round block type="primary"
                        style="margin-top: 1.6vw"   @click="confirm" :disabled="btnDisabled" >
              确认上报
            </van-button>
          </div>
        </van-form>


</template>

<script>

import Picker from "../../components/Picker";
import DatetimePicker from "../../components/DatetimePicker";
import DatePicker from "../../components/DatePicker";
import _ from "lodash";
import RFQVehicle from "./RFQVehicle.vue";
import {Toast} from "vant";
import {reactive} from "vue";
import "vue3-video-play/dist/style.css";
import {videoPlay} from "vue3-video-play";
import 'mui-player/dist/mui-player.min.css'
import MuiPlayer from 'mui-player'
import globals from '../../public/js/global'
var photoJdk = {
  isSDKReady: false,
  init: function (assas) {
    var option = {
      portName: 'system.security.Security.getJsApiSignModel',
      data: {
        url: location.href
      },
      successCallback: function (data) {
        var jsApiSign = data.data.jsApiSign;
        photoJdk.initWechatJsConfig(jsApiSign);
      }
    };
    assas.$sapi.callPort(option)
  },
  //初始化微信js配置
  initWechatJsConfig: function (jsApiSign) {
    var me = this;
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: jsApiSign.appID, // 必填，公众号的唯一标识
      timestamp: jsApiSign.timestamp, // 必填，生成签名的时间戳
      nonceStr: jsApiSign.nonceStr, // 必填，生成签名的随机串
      signature: jsApiSign.signature,// 必填，签名
      jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
    });
    wx.ready(function () {
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，
      // config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
      // 对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      me.isSDKReady = true;
      console.log('JsApiJDK 签名验证正常:');
    });
    wx.error(function (res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，
      // 也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log('JsApiJDK 签名验证异常:' + JSON.stringify(res));
    });
    //wechat jdk 注册
  },
  //开始拍照 callback(serverId),serverId为多媒体ID
  startCapture: function (callback) {
    var me = this;
    if (!photoJdk.isSDKReady) {
      console.log("拍照SDK正在初始化，稍后重试");
      console.log(1)
      callback("未成功获取")
      return;
    }
    wx.getLocation({
      type: 'gcj02',
      success (res) {
        const latitude = res.latitude
        const longitude = res.longitude
        const speed = res.speed
        const accuracy = res.accuracy
        callback(latitude+","+longitude)
      }
    })
  }
}
export default {
  name: "RFQReserve",
  components: { Picker},
  props:{
    typeStatusCode: {
      default: "",
      type: String
    },
  },
  data() {
    return {
      active: '',
      showTab: false,
      id: '',
      code: '',
      name: '',
      lnglat:'',
      quotePrice:'',
      nameStart:'',
      nameEnd:'',
      driverName:"",
      driverPhone:"",
      "statusDic.code":"",
      'dispatchOrderRfq.dispatchOrder.addressStart':'',
      'dispatchOrderRfq.dispatchOrder.addressEnd':'',
      'currency.name':'',
      'currency.id':'',
      "vehicle.id":"",
      "carriage.id":"",
      "coupler.id":"",
      'dispatchOrderRfq.code':'',
      'dispatchOrderRfq.dispatchOrder.code':'',
      'dispatchOrderRfq.dispatchOrder.volume':'',
      'dispatchOrderRfq.needReachTime': '',
      'dispatchOrderRfq.needStartTime':'',
      'dispatchOrderRfq.mileage':'',
      planDate: new Date().pattern('yyyy-MM-dd').toString(),
      btnDisabled: true,
    };
  },
  created() {
    this.loadData();

  },
  mounted() {
    //this.checkPass();
  },
  beforeUnmount() {
    if (_.isFunction(globals.mp.destory)) {
      globals.mp.destory()
    }
  },
  watch: {
    id: function (val) {
      if (!_.isEmpty(val)) {
        this.showTab = true;
      } else {
        this.showTab = false;
        this.purposeReadOnly = false;
      }
    }
  },
  methods: {
    loadData() {
      photoJdk.init(this)
      var me = this;
      var id = me.$route.params.id;
      if (!_.isEmpty(id)) {
        me.btnDisabled = true;
        me.showDialog = false;
        var option = {
          portName: 'tms.TmsDispatchOrderRfqCarrier.getTmsDispatchOrderRfqCarrier',
          data: {id: id},
          needLoading: false,
          successCallback: function (data) {
            var entities = data.entities;
            if (!_.isEmpty(entities)) {
              var entity = entities[0];
              var _data = me.$data;
              for (var attrName in entity) {
                if (_data[attrName] != undefined) {
                  _data[attrName] = entity[attrName];
                }
              }
              if (entity['confirmStatus.code'] === 'PRICE_STATUS_HAD_CONFIRM') {
                me.btnDisabled = true;
              } else {
                me.btnDisabled = false;
              }


              // var statusDicCode = entity['confirmStatus.code'];
              // me.purposeReadOnly = (statusDicCode=='VISITOR_RESERVE_STATUS_APPROVING'|| statusDicCode=='VISITOR_RESERVE_STATUS_PASS')
              // me.btnDisabled = false;
            }
          }
        }
        this.$sapi.callPort(option);
      } else {
        var visitor = this.$sapi.storage.getItemJson('visitor');
        this.$data['visitor.id'] = visitor.id;
        this.name = visitor.name;
        this.tel = visitor.tel;
        this.unit = visitor.unit;
        this['genderDic.id'] = visitor['genderDic.id'];
      }
    },

    beforeRead: function (file) {
      return this.$sapi.compressImg(file, 200, true);
    },
    afterRead: function (file) {
      this.$sapi.setUploadFileModel(file);
    },
    onCarConfirm(value) {
      this.brand = value.brand;
      this['typeDic.id'] = value['typeDic.id'];

      var drivingLicenseFront1 = value['drivingLicenseFront'];
      var drivingLicenseBack1 = value['drivingLicenseBack'];
      var operationAttach1 = value['operationAttach'];
      if (drivingLicenseFront1.length !== 0) {
        var option = {
          portName: 'system.foundation.Attach.copyImage',
          data: {fileID: drivingLicenseFront1[0]['fileID']},
          needLoading: false,
          successCallback: (data) => {
            this.drivingLicenseFront = [data.data['fileModel']];
          }
        }
        this.$sapi.callPort(option);
      }
      if (drivingLicenseBack1.length !== 0) {
        var option1 = {
          portName: 'system.foundation.Attach.copyImage',
          data: {fileID: drivingLicenseBack1[0]['fileID']},
          needLoading: false,
          successCallback: (data) => {
            this.drivingLicenseBack = [data.data['fileModel']];
          }
        }
        this.$sapi.callPort(option1);
      }
      if (operationAttach1.length !== 0) {
        var option2 = {
          portName: 'system.foundation.Attach.copyImage',
          data: {fileID: operationAttach1[0]['fileID']},
          needLoading: false,
          successCallback: (data) => {
            this.operationAttach = [data.data['fileModel']];
          }
        }
        this.$sapi.callPort(option2);
      }
    },
    confirm() {
      var me = this;
      photoJdk.startCapture(function (lnglat){
        var values=[];
        console.log(lnglat)
        values['id'] = me.id;
        values['quotePrice'] = me['quotePrice'];
        values['lnglat']=lnglat;
        values['currency.id'] = me['currency.id'];
        var option = {
          portName: 'tms.TmsDispatchOrderRfqCarrier.confirm',
          data: values,
          needLoading: false,
          successCallback: (data) => {
            me.id = data.data.id;
            me.loadData()
            me.$notify({type: 'success', message: '已上报'})
          }
        };
        me.btnDisabled = true;
        try {
          me.$sapi.callPort(option);
        } finally {
          me.btnDisabled = false;
        }
      })

    },
    onTabChange(index) {
      if (index === "msg") {
        return;
      }
      var purposeRaw = this.$refs.purposePicker.getSelectedRaw();
      var carTypeRaw = this.$refs.carTypePicker.getSelectedRaw();
      this.isLoad = (purposeRaw.code == 'VEHICLE_PURPOSE_LOAD');
      this.isHazardous = (carTypeRaw.code == 'VISITOR_CAR_TYPE_HAZARDOUS_TRUCK');
    },
    onSubmit(values) {
      values['dispatchOrder.id'] = this['id'];
      var me = this;
      var option = {
        portName: 'tms.TmsDispatchOrderRfqCarrier.fillPriceVapp',
        data: values,
        needLoading: false,
        successCallback: (data) => {
          this.loadData()
          this.$notify({type: 'success', message: '已保存'})
        }
      };
      this.btnDisabled = true;
      try {
        this.$sapi.callPort(option);
      } finally {
        this.btnDisabled = false;
      }
    },
    onSubmitReserve() {
      var option = {
        portName: 'osp.car.VehicleReserve.submitVehicleReserve',
        data: {ids: [this.id]},
        needLoading: false,
        successCallback: (data) => {
          this.$notify({type: 'success', message: '提交成功'});
          this.purposeReadOnly = true;
        },
        finallyCallback: () => {
          this.btnDisabled = false;
        }
      };
      this.btnDisabled = true;
      this.$sapi.callPort(option);
    },
    pageChange(){
      this.active= "msg";
    },
    beforeChange() {
      var id = this.id;
      var beforeChange = false;
      if (!_.isEmpty(id)) {
        beforeChange = true;
      } else {
        beforeChange =  false;
      }
      return beforeChange;
    }
  }
}
</script>

<style scoped>

</style>
