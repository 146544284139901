<template>
  <van-sticky :offset-top="30" teleport="body">
    <div style="margin: 4.26667vw; position: relative">
      <van-button round block type="primary" @click="addMaterial">添加车辆</van-button>
    </div>
  </van-sticky>


  <GridList title="车辆" port-name="tms.TmsDispatchOrderRfqCarrierDriver.getTmsDispatchOrderRfqCarriersDriver"
            :request-data="{'dispatchOrderRfqCarrier.id': dispatchOrderRfqCarrierId}"
            :dataList="dataList" style="margin: 4.26667vw;" ref="grid">

    <div class="list_cell">
      <div style="width: 16vw;"><span class="custom-title">司机</span></div>
      <div style="width: 21.33333vw;"><span class="custom-title">手机号</span></div>
      <div style="width: 16vw;"><span class="custom-title">车辆</span></div>
      <div style="width: 16vw;"><span class="custom-title">车厢</span></div>
      <div style="width: 16vw;"><span class="custom-title">车挂</span></div>
    </div>
    <ListCard
        v-for="item in dataList"
        :key="item.id"
        :onDel="onDel"
        :id="item.id"
    >

      <div @click="editMaterial(item.id)">
        <table>

        </table>
        <div class="list_cell">
          <div style="width: 16vw;"><span class="custom-title">{{ item['driver.name'] }}</span></div>
          <div style="width: 21.33333vw;"><span class="custom-title">{{ item['driver.phone'] }}</span></div>
          <div style="width: 16vw;"><span class="custom-title">{{ item['vehicle.carCodeHead'] }}</span></div>
          <div style="width: 16vw;"><span class="custom-title">{{ item['carriage.code'] }}</span></div>
          <div style="width: 16vw;"><span class="custom-title">{{ item['coupler.licensePlate'] }}</span></div>
        </div>
      </div>
      <van-divider style="--van-divider-margin: 1.33333vw 3.2vw 1.33333vw 3.2vw"
                   :style="{ color: '#c1c1c4', borderColor: '#c1c1c4'}"/>
    </ListCard>
  </GridList>

  <div style="margin: 4.26667vw">
    <van-button round block type="primary" @click="pageChangeToOne">
      上一步
    </van-button>
  </div>
<!--  <div style="margin: 4.26667vw">-->
<!--    <van-button round block type="primary" @click="onSubmitReserve" :disabled="btnDisabled">-->
<!--      提交预约-->
<!--    </van-button>-->
<!--  </div>-->
  <edit-material-popup
      v-model:show="showEdit"
      ref="editMaterial"
      :is-load="this.isLoad"
      :is-hazardous="this.isHazardous"
      @onConfirm="confirmEdit"
  />
</template>

<script>
import ListCard from "../../components/ListCard";
import _ from "lodash";
import {Dialog} from "vant";
import GridList from "../../components/GridList";
import SelectMaterial from "./SelectMaterial";
import EditMaterialPopup from "./EditMaterialPopup";


export default {
  name: "RFQVehicle",
  components: {EditMaterialPopup, ListCard, GridList},
  created() {

  },
  props: {
    dispatchOrderRfqCarrierId: {
      default: '',
      type: String
    },
    isLoad: {
      default: true,
      type: Boolean
    },
    isHazardous: {
      default: false,
      type: Boolean
    },
    back: {
      default: null,
      type: Function
    }
  },
  data() {
    return {
      dataList: [],
      showEdit: false,
      btnDisabled: false
    };
  },

  watch:{
    showEdit: function (val) {
      if (val) {
        return;
      }
      this.$refs.editMaterial.$data.item = {
        id: '',
        'reserve.id': this.reserveID,
        'material.id': '',
        'material.name': '',
        qty: 0,
        orderQty: 0,
        orderCode: '',
        unit: 'kg',
        'platform.id': '',
        pointName: '',
        principal: '',
        principalName: '',
        pointPhone: '',
        supplier: '',
        supplierNo: ''
      };
    }
  },

  methods: {
    onAdd: function () {

    },
    onDel: function (id) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定删除该记录？',
      })
          .then(() => {
            var option = {
              portName: 'osp.car.ReserveMaterial.delReserveMaterial',
              data: {ids: [id]},
              needLoading: false,
              successCallback: () => {
                this.$notify({type: 'success', message: '删除成功'})
                this.$refs.grid.onRefresh();
              }
            }
            this.$sapi.callPort(option);
          })
          .catch(() => {

          });
    },
    addMaterial() {
      this.showEdit = true;
      this.$refs.editMaterial.$data.item = {
        id: '',
        'reserve.id': this.reserveID,
        'material.id': '',
        'material.name': '',
        qty: 0,
        orderQty: 0,
        orderCode: '',
        unit: 'kg',
        'platform.id': '',
        pointName: '',
        principal: '',
        principalName: '',
        pointPhone: '',
        supplier: '',
        supplierNo: ''
      }
    },
    editMaterial(id) {
      this.showEdit = true;
      var option = {
        portName: 'osp.car.ReserveMaterial.getReserveMaterial',
        data: {id: id},
        needLoading: false,
        successCallback: (data) => {
          var entity = data.entities[0];
          this.$refs.editMaterial.$data.isPRo = entity['material.name'] === '项目物资' ? 'true' : 'false';
          this.$refs.editMaterial.$data.item = {
            id: entity.id,
            'reserve.id': entity['reserve.id'],
            'material.id': entity['material.id'],
            'material.name': entity['material.name'],
            qty: entity['qty'],
            orderQty: 0,
            orderCode: '',
            unit: entity['unit'],
            'point.id': entity['point.id'],
            principal: entity['principal'],
            principalName: entity['principalName'],
            supplier: entity['supplier'],
            supplierNo: entity['supplierNo']
          };
        },
        finallyCallback: () => {
          this.btnDisabled = false;
        }
      }
      this.btnDisabled = true;
      this.$sapi.callPort(option);
    },
    confirmEdit(value) {
      var option = {
        portName: 'osp.car.ReserveMaterial.saveReserveMaterials',
        data: value,
        needLoading: false,
        successCallback: () => {
          this.$refs.grid.onRefresh();
        }
      }
      this.$sapi.callPort(option);
    },
    onSubmitReserve() {
      var option = {
        portName: 'osp.car.VehicleReserve.submitVehicleReserve',
        data: {ids: [this.reserveID]},
        needLoading: false,
        successCallback: (data) => {
          this.$notify({type: 'success', message: '提交成功'});
        },
        finallyCallback: () => {
          this.btnDisabled = false;
        }
      };
      this.btnDisabled = true;
      this.$sapi.callPort(option);
    },
    pageChangeToOne(){
      if (this.back) {
        this.back();
      }
    }
  },
}
</script>

<style scoped>
.list_cell {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 3px 12px 3px 12px;
  overflow: hidden;
  color: var(--van-cell-text-color);
  font-size: var(--van-cell-font-size);
  line-height: var(--van-cell-line-height);
  background: var(--van-cell-background-color);
}
</style>